import { Form, InputNumber, InputProps } from 'antd';
import { NamePath } from 'antd/es/form/interface';

const { Item } = Form;
interface FormInputNumberRule {
	name: NamePath;
	label?: string;
	required?: boolean;
	className?: string;
	fieldKey?: NamePath;
	onChange?: (value: number | null) => void;
	disabled?: boolean;
	placeholder?: string;
	outerClass?: string;
	inputClass?: string;
	testid?: string;
	min?: number;
	max?: number;
	addonBefore?: string;
	addonAfter?: string;
	formatter?: (v: any) => any;
	inputProps?: InputProps;
}

const FormInputNumber = (props: FormInputNumberRule) => {
	const {
		label,
		name,
		min,
		max,
		testid,
		fieldKey,
		addonBefore,
		addonAfter,
		onChange,
		formatter,
		placeholder,
		outerClass = 'col-span-6',
		required = false,
		disabled = false,
		inputProps = {}
	} = props;

	return (
		<div className={outerClass}>
			<Item label={label} name={name} rules={[{ required }]} data-testid={testid || name} fieldKey={fieldKey}>
				<InputNumber
					min={min}
					max={max}
					formatter={formatter}
					placeholder={placeholder}
					disabled={disabled}
					onChange={onChange}
					className='w-full'
					addonBefore={addonBefore}
					addonAfter={addonAfter}
					{...(inputProps as any)}
				/>
			</Item>
		</div>
	);
};
export default FormInputNumber;
