import { Form, FormInstance } from 'antd';
import { memo, PropsWithChildren } from 'react';

interface FormWrapperRule<T = any> {
	form: FormInstance<T>;
	onFinish: (data: T) => void;
	children: PropsWithChildren<any>;
	initialValues?: {};
	className?: string;
	layout?: 'horizontal' | 'vertical' | 'inline';
}

const FormWrapper = (props: FormWrapperRule) => {
	const { children, onFinish, className = 'row', layout = 'vertical', ...rest } = props;

	return (
		<Form {...rest} onFinish={onFinish} className={className} layout={layout} size='large' data-testid='antd-form'>
			{children}
		</Form>
	);
};

export default memo(FormWrapper);
