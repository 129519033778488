import { ReactNode } from 'react';
import { Form, Input, InputProps } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { LiteralUnion } from 'rc-input/lib/utils/types';

const { Item } = Form;

// type LiteralUnion<T extends U, U = string> = T | (U & { zz_IGNORE_ME?: never });

type InputType = LiteralUnion<
	| 'button'
	| 'checkbox'
	| 'color'
	| 'date'
	| 'datetime-local'
	| 'email'
	| 'file'
	| 'hidden'
	| 'image'
	| 'month'
	| 'number'
	| 'password'
	| 'radio'
	| 'range'
	| 'reset'
	| 'search'
	| 'submit'
	| 'tel'
	| 'text'
	| 'time'
	| 'url'
	| 'week',
	string
>;

interface FormInputRule {
	name: NamePath;
	label?: ReactNode;
	className?: string;
	formItemClassName?: string;
	required?: boolean;
	type?: InputType;
	placeholder?: string;
	inputProps?: InputProps;
	readOnly?: boolean;
}

const FormInput = (props: FormInputRule) => {
	const {
		label = '',
		name,
		required = false,
		readOnly = false,
		type,
		placeholder,
		inputProps,
		formItemClassName = 'mb-3',
		className = 'form-input'
	} = props;

	return (
		<Item
			label={label}
			name={name}
			rules={[
				{
					required,
					message: `${label || placeholder || 'Field'} is required`
				}
			]}
			className={`s-label ${formItemClassName}`}
			data-testid={name}
		>
			<Input size='middle' type={type} readOnly={readOnly} placeholder={placeholder} className={className} {...inputProps} />
		</Item>
	);
};

export default FormInput;
