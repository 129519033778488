import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { enableMapSet, enablePatches } from 'immer';
import { ConfigProvider, notification } from 'antd';
import * as Sentry from '@sentry/react';
import { ConfigProviderProps } from 'antd/es/config-provider';
import { IS_PRODUCTION, validateMessages } from 'dashboard/constants';
import ErrorBoundary from 'dashboard/ShareComponents/ErrorBoundry';
import RegistrationQuestionnaire from 'dashboard/pages/Authorization/Registration/components/RegistrationQuestions';
import Loadable from 'builder/shared/Loadable';

import 'dashboard/utils/dayjsplugins';
import 'shared/style.css';
import 'antd/dist/reset.css';
import './dashboard/assets/css/boards.css';
import './dashboard/assets/css/kanban.css';

const BuilderView = Loadable(() => import('builder'));
const DashboardView = Loadable(() => import('dashboard'));

const config: ConfigProviderProps = {
	form: { colon: false, requiredMark: true, validateMessages: validateMessages },
	theme: { token: { colorPrimary: '#6D41BE' } }
};

if (IS_PRODUCTION) {
	Sentry.init({
		dsn: 'https://fe434572d79b4aa198e257263c696b86@o4504873309569024.ingest.sentry.io/4505050694221824',
		integrations: [
			new Sentry.BrowserTracing(),
			new Sentry.Replay({
				maskAllText: false,
				maskAllInputs: false,
				blockAllMedia: false
			})
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

notification.config({ placement: 'top', duration: 3 });

enablePatches();
enableMapSet();

const App = () => {
	return (
		<ErrorBoundary>
			<ConfigProvider {...config}>
				<BrowserRouter>
					<Routes>
						<Route path='/complete-registration' element={<RegistrationQuestionnaire />} />
						<Route path='/builder/*' element={<BuilderView />} />
						<Route path='/*' element={<DashboardView />} />
					</Routes>
				</BrowserRouter>
			</ConfigProvider>
		</ErrorBoundary>
	);
};

export default App;
