import { useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { Button, Checkbox, Form, Radio, Steps } from 'antd';
import { FormWrapper } from 'dashboard/ShareComponents/Form';
import If from 'dashboard/ShareComponents/If';
import './index.css';

import { ReactComponent as FlatIconLight } from 'dashboard/assets/icons/registerPage/flight-light.svg';
import { ReactComponent as LinesIcon } from 'dashboard/assets/icons/registerPage/lines.svg';
import { QuestionType } from './index.d';
import { Link } from 'react-router-dom';

interface WizardProps {
	steps: QuestionType[];
	allowSkip?: boolean;
	loading?: boolean;
	onFinish: (values: any) => void;
	rightComponent?: React.ReactNode;
	error?: string;
}

const Wizard = (props: WizardProps) => {
	const { steps, onFinish, rightComponent, error, loading } = props;
	const [form] = useForm();
	const [current, setCurrent] = useState(0);
	const [isValid, setIsValid] = useState(false);
	const [values, setValues] = useState<any>({});

	const currentItem = steps[current];

	const onNext = async (_current = current) => {
		const v = await form.validateFields();
		const newValues = { ...values, ...v };
		if (steps.length === _current + 1) {
			onFinish(newValues);
		} else {
			setValues(newValues);
			const nextItem = steps[_current + 1];
			const shouldView = !!nextItem.condition ? nextItem.condition(newValues) : true;
			setCurrent(_current + 1);
			if (shouldView) {
				if (nextItem?.name) {
					setIsValid(nextItem.skipValidation || form.getFieldValue(nextItem.name)?.length > 0);
				}
			} else {
				onNext(_current + 1);
			}
		}
	};

	const onPrev = async (_current = current) => {
		const previousStep = steps[_current - 1] || {};
		const shouldView = !!previousStep.condition ? previousStep.condition(values) : true;
		setIsValid(true);
		if (shouldView) {
			setCurrent(_current - 1);
			return;
		} else {
			onPrev(_current - 1);
		}
	};

	return (
		<FormWrapper form={form} onFinish={() => {}} className='wizard-form'>
			{/*  grid grid-cols-6 p-6 */}
			{/* Top icon  */}
			<div>
				<span className='absolute right-12 top-52'>
					<FlatIconLight />
				</span>
				<span className='absolute left-36 bottom-2 dash-icon-t-c'>
					<FlatIconLight />
				</span>
				<span className='absolute left-0 top-0'>
					<LinesIcon />
				</span>
				<span className='absolute right-0 bottom-0 rotate-180'>
					<LinesIcon />
				</span>
				<div className='absolute right-96 top-24'>
					<div className='w-7 bg-violet-600 h-[5px] rounded rotate-[-38deg]'></div>
					<div className='w-7 bg-violet-600 h-[5px] rounded rotate-[-38deg] mt-1 mb-6'></div>
				</div>
				<div className='absolute left-96 top-24'>
					<div className='w-7 bg-green-600 h-[5px] rounded rotate-[-38deg]'></div>
					<div className='w-7 bg-green-600 h-[5px] rounded rotate-[-38deg] mt-1 mb-6'></div>
				</div>
			</div>
			{/* <div className='main-wrap mx-auto pt-24'> */}
			<div className='main-wrap '>
				{rightComponent ? rightComponent : <></>}
				{/* <div className='m-0 py-10 flex flex-col gap-2 wizard-wapper col-span-4'> */}
				<div className='m-0 py-10 pt-0  wizard-wapper '>
					<div className='m-0 wizard-row-one'>
						{/* <h6 className='m-0'>
							{current + 1}/{steps.length}
						</h6> */}
					</div>

					<div className='m-0 wizard-row-two'>
						<Steps current={current} progressDot items={steps.map(() => ({}))} />
					</div>

					<div className='m-0 wizard-row-three pt-4 pb-2 text-center'>
						<h4 className='m-0 heading-lg'>{currentItem.title}</h4>
						<p className='m-0 p-0 text-gray-400'>{currentItem.description}</p>
					</div>
					<div className='mt-3 wizard-tabs'>
						<If _={currentItem.type === 'radio'}>
							<Form.Item name={currentItem.name} className='max-w-md m-auto'>
								<Radio.Group className='grid grid-cols-1 gap-3' onChange={() => setIsValid(true)}>
									{currentItem?.options?.map((option, index) => (
										<Radio
											key={index}
											value={option.value}
											className='m-0 py-2 px-4 flex flex-row gap-3 wizard-tabs-data wizard-circle'
										>
											<p className='m-0'>{option.label}</p>
											<small>{option.text}</small>
										</Radio>
									))}
								</Radio.Group>
							</Form.Item>
						</If>
						<If _={currentItem.type === 'checkbox'}>
							<Form.Item name={currentItem.name}>
								<Checkbox.Group
									className='grid grid-cols-2 gap-3'
									onChange={() => setIsValid(form.getFieldValue(currentItem.name)?.length > 0)}
								>
									{currentItem?.options?.map((option, index) => (
										<Checkbox
											key={index}
											value={option.value}
											className='m-0 py-2 px-4 flex flex-row gap-3 wizard-tabs-data wizard-circle'
										>
											<p className='m-0'>{option.label}</p>
										</Checkbox>
									))}
								</Checkbox.Group>
							</Form.Item>
						</If>
						<If _={currentItem.type === 'component'}>{currentItem.component ? currentItem.component({ form, onNext }) : null}</If>
						{props.allowSkip && (
							<div className='max-w-md text-right m-auto mt-5'>
								<Link to={'/dashboard'}>Skip to Dashboard</Link>
							</div>
						)}
					</div>
					{/* <div className='m-0 pt-7 pb-2 flex gap-4 wizard-btn-warp'> */}
					<div className='m-0 pt-7 pb-2  wizard-btn-warp text-center'>
						{currentItem.hideNextButton ? (
							<></>
						) : (
							current > 0 && (
								<button className='wizard-white-btn ' type='button' onClick={() => onPrev()}>
									Back
								</button>
							)
						)}
						{currentItem.hideNextButton ? (
							<></>
						) : (
							<>
								{!!error && <div className='error'>{error}</div>}
								<Button
									type='primary'
									htmlType='submit'
									className='wizard-purple-btn ml-3'
									onClick={() => onNext()}
									disabled={!isValid}
									loading={!!loading}
								>
									{current < steps.length - 1 ? 'Next' : 'Submit'}
								</Button>
							</>
						)}
					</div>
				</div>
			</div>
		</FormWrapper>
	);
};

export default Wizard;
