import axios, { RawAxiosRequestConfig } from 'axios';
import { API_URL } from '../constants';
import type { EndpointsType } from 'dashboard/types/HttpEndpoints';
import { cleanObject } from './helper';

export type HttpMethodsType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

interface AxiosConfig extends RawAxiosRequestConfig<any> {
	noToken?: boolean;
}

const requestTokens: Record<string, any> = {};

const HTTP = async (method: HttpMethodsType, endpoint: EndpointsType, data: any = {}, options?: AxiosConfig) => {
	const { noToken, ...configOptions } = options || {};
	const token = localStorage.getItem('t') || '';
	const cancelToken = axios.CancelToken.source();
	const { pathname } = window.location;

	if (!noToken && (!token || token === '')) {
		return {};
	}
	data = method === 'GET' && data ? cleanObject(data) : data;
	const url = method === 'GET' && data && Object.keys(data).length > 0 ? `${endpoint}?${new URLSearchParams(data).toString()}` : endpoint;
	const baseUrl = options?.baseURL || API_URL;
	const headers: Record<string, any> = {
		authorization: token,
		...options?.headers
	};
	if (baseUrl === API_URL) {
		// Add access token only if the request is from api server
		headers['access-token'] = localStorage.getItem('a') || '';
	}
	const axiosConfig: RawAxiosRequestConfig<any> = {
		url: `${baseUrl}${url}`,
		method,
		data,
		headers,
		cancelToken: options?.cancelToken || cancelToken.token,
		...configOptions
	};

	if (method === 'GET') {
		if (!requestTokens[pathname]) {
			requestTokens[pathname] = [];
		}
		requestTokens[pathname].push(cancelToken.cancel);
	}

	let response;

	try {
		const { data } = await axios(axiosConfig);
		response = data;
	} catch (error: any) {
		if (error.response?.status === 401 && error.response?.data === 'Login Required') {
			// TODO:: show login modal
			localStorage.clear();
			window.location.href = '/login';
		} else {
			throw error?.response?.data;
		}
	}
	return response;
};

export default HTTP;

export const REQUEST = async (
	method: HttpMethodsType,
	endpoint: string,
	data: {} = {},
	no_token?: Boolean,
	baseURL?: string,
	headers = {},
	options: RawAxiosRequestConfig = {}
) => {
	const token = localStorage.getItem('t');
	const cancelToken = axios.CancelToken.source();
	if (!no_token && (!token || token === '')) {
		return {};
	}
	const { pathname } = window.location;
	let response: any;
	try {
		const requestParams: any = {
			method,
			url: `${!!baseURL ? baseURL : API_URL}${endpoint}`,
			data: data,
			headers: { authorization: token, ...headers },
			...options
		};
		if (method === 'GET') {
			requestParams.cancelToken = cancelToken.token;
			if (!requestTokens[pathname]) {
				requestTokens[pathname] = [];
			}
			requestTokens[pathname].push(cancelToken.cancel);
		}
		response = await axios(requestParams);
		if (response?.data?.['login_failed']) {
			return;
		}
		if (response?.data?.['access_denied']) {
			response = {};
		}
	} catch (error) {
		response = error;
	}
	return response;
};
