import { ReactNode } from 'react';
import { Form, Checkbox, CheckboxProps } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const { Item } = Form;

interface FormCheckboxRule {
	name: NamePath;
	label: ReactNode;
	className?: string;
	outerClass?: string;
	required?: boolean;
	checkboxProps?: CheckboxProps;
	isNumber?: boolean; // Convert true/false to 1/0
}

const FormCheckbox = (props: FormCheckboxRule) => {
	const { label, name, checkboxProps, className, outerClass = 'col-span-6', isNumber = false, required = false } = props;
	const form = Form.useFormInstance();

	const onChange = (e: CheckboxChangeEvent) => form.setFieldValue(name, e.target.checked ? 1 : 0);

	return (
		<div className={outerClass}>
			<Item name={name} valuePropName='checked' rules={[{ required }]} className={className || 'm-0'}>
				<Checkbox data-testid={name} {...(isNumber && { onChange })} {...checkboxProps}>
					{label}
				</Checkbox>
			</Item>
		</div>
	);
};

export default FormCheckbox;
