import React from 'react';
import Gleap from 'gleap';

import ReactDOM from 'react-dom/client';
import App from './App';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
// Please make sure to call this method only once!
Gleap.initialize('XXS9iFnVapSYV5WfPRgCAr6E3YT8tWlJ');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
