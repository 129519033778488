import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { DefaultOptionType, SelectProps } from 'antd/es/select';
import { ReactNode } from 'react';
import { getPopupContainer } from 'dashboard/utils/helper';

const { Item } = Form;

interface FormDropdownRule {
	name: NamePath;
	options: DefaultOptionType[];
	label?: ReactNode;
	placeholder?: string;
	className?: string;
	selectClassName?: string;
	required?: boolean;
	disabled?: boolean;
	mode?: 'multiple' | 'tags' | undefined;
	selectProps?: SelectProps;
	allowClear?: boolean;
}

const FormDropdown = (props: FormDropdownRule) => {
	const {
		label = '',
		name,
		options,
		mode,
		selectProps,
		placeholder,
		className,
		selectClassName,
		required = false,
		disabled = false,
		allowClear = false
	} = props;

	return (
		<Item
			className={className || 'm-0 mb-3 s-label'}
			label={label}
			name={name}
			rules={[
				{
					required,
					message: `${label || placeholder || name} is required`
				}
			]}
			data-testid={Array.isArray(name) ? name[0] : name}
		>
			<Select
				mode={mode}
				className={selectClassName || 'm-drop'}
				showSearch
				optionFilterProp='children'
				placeholder={placeholder || `Select ${typeof label === 'string' ? label : ''}`}
				getPopupContainer={getPopupContainer}
				options={options}
				disabled={disabled}
				allowClear={allowClear}
				{...selectProps}
			/>
		</Item>
	);
};

export default FormDropdown;
