import { setToken } from 'dashboard/pages/Authorization/Login/helper';
import dayjs from 'dayjs';
import { get, startCase } from 'lodash-es';
import HTTP from './https';
import { FocusEventHandler } from 'react';

export const removeUndefinedValues = (data: any, cb?: (item: any, key: any, value: any) => void) => {
	let newData = { ...data };

	Object.entries(newData).forEach(([key, value]: [string, any]) => {
		if (value === undefined || value === null) {
			delete newData[key];
		}

		if (cb) cb(newData, key, value);
	});
	return newData;
};

export const formatNumber = (val: number, fixed: number = 0, sign: boolean = false) => {
	if (isNaN(val)) val = 0;
	let number = Number(val)
		.toFixed(fixed)
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	if (sign) number = '$' + number;
	return number;
};

export const isMobile = () => window.matchMedia && window.matchMedia('(max-width: 480px)').matches;
export const isIpad = () => window.matchMedia && window.matchMedia('(max-width: 1024px)').matches;

export const textSorter = (a: any, b: any, selector: string) => {
	return String(get(a, selector))?.localeCompare(String(get(b, selector)));
};

export const numberSorter = (a: any, b: any, selector: string) => {
	return get(a, selector) - get(b, selector);
};

export const AssignKey = (docs: any[], keyName = '_id'): any[] => {
	return docs?.length > 0
		? docs.map((item, index: number) => {
				item.key = get(item, keyName, Date.now()) + index;
				return item;
		  })
		: [];
};

export const formatUsername = ({ first_name = '', last_name = '' }) => `${first_name} ${last_name}`;

export const isValidId = (id: string): boolean => (id ? new RegExp('^[0-9a-fA-F]{24}$').test(id) : false);

export const isValidParamId = (id: string) => !isNaN(parseInt(id!));

export const convertUrlIds = (url: string) => {
	const splitted = url.split('/');
	return splitted.map((i: string) => (isValidId(i) ? ':id' : '')).join('/');
};

export const slugRegExp = new RegExp(/^[a-z0-9]+(?:-[a-z0-9]+)*$/g);
export const generateSlug = (title: string = '') => {
	return title
		.toLowerCase()
		.replace(/\s+/g, '-')
		.replace(/[^\w-]+/g, '');
};

export const checkLoginSession = (date: Date = new Date()) => dayjs(date).isBefore(dayjs(localStorage.getItem('LOGIN_SESSION_TIME')));

export const updateUserSession = () => localStorage.setItem('LOGIN_SESSION_TIME', dayjs().add(dayjs.duration(1, 'hour')).toISOString());

export const validateEmail = (email: string) => {
	if (!email) return false;
	const emailParts = email.split('@');
	if (
		emailParts.length !== 2 ||
		emailParts[0].length > 64 ||
		emailParts[1].length > 255 ||
		emailParts[1].split('.').some((p: string) => p.length > 63)
	)
		return false;
	const EMAIL_REGX =
		/^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
	return EMAIL_REGX.test(email) ? true : false;
};
export const formatPhoneNumber = (phone: string) =>
	phone
		? phone
				?.replace(/\D/g, '')
				.replace(/^(\d{3})/, '($1) -')
				.replace(/-(\d{3})/, '$1-')
				.replace(/(\d)-(\d{4}).*/, '$1-$2')
		: '';

export const copyTextToClipboard = async (text: string) => {
	const listener = (e: any) => {
		e.clipboardData.setData('text/html', `${text}`);
		e.clipboardData.setData('text/plain', `${text}`);
		e.preventDefault();
	};
	document.addEventListener('copy', listener);
	document.execCommand('copy');
	document.removeEventListener('copy', listener);
};

export const getPopupContainer = (node?: any) => {
	if (node) return node.parentNode;
	return document.body;
};

export const moveCursorToEnd: FocusEventHandler<HTMLTextAreaElement> = (e) => {
	const input = e.target;
	input.selectionStart = input.selectionEnd = input.value.length;
};

export const joinClasses = (...classes: any[]) => classes.filter(Boolean).join(' ');

export const onLogout = async (e?: any, proxy?: boolean) => {
	if (proxy) {
		const res = await HTTP('POST', 'users/proxy/logout');
		setToken(res);
		window.location.href = '/admin/settings/users';
	} else {
		e?.preventDefault();
		localStorage.removeItem('t');
		localStorage.removeItem('u');
		window.location.href = '/login';
	}
};

export const mapArrayToOptions = (array: any[]) => array.map((item: any) => ({ value: item, label: item }));

export const mapDropdownOptions = (array: any[], key: string) => array.map((item: any) => ({ ...item, value: item?.id, label: get(item, key, '') }));

export const mapFileSize = (bytes: number = 0, decimal = 2) => {
	if (bytes === 0) return '0 Bytes';
	const k = 1024;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(decimal)) + ' ' + sizes[i];
};

export const getRandomColor = () => '#' + Math.floor(Math.random() * 16777215).toString(16);

export const getRandomDarkColor = () => {
	const colors = ['#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e', '#f1c40f', '#e67e22', '#e74c3c'];
	return colors[Math.floor(Math.random() * colors.length)];
};

export const cleanObject = (obj: Record<string | number, any>) => {
	const newObj: Record<string | number, any> = {};
	for (const key in obj) {
		if (!!obj[key]) newObj[key] = obj[key];
	}
	return newObj;
};

export const cleanArray = (array: any[]) => array.filter((i) => !!i);

export const filterDomainName = (domain: string) => {
	// remove http:// or https://
	domain = domain.replace(/(^\w+:|^)\/\//, '');
	// remove www.
	return domain.startsWith('www.') ? domain.replace('www.', '') : domain;
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getAvatarLabel = (i: Record<string, any>) => `${i?.first_name?.charAt(0) || ''}${i?.last_name?.charAt(0) || ''}`.toUpperCase();

export const sanatizeText = (text: string) => startCase(text.replace(/_/g, ' '));

export const toArray = (value: Map<any, any> | Object) => {
	if (value instanceof Map) {
		return Array.from(value.values());
	} else if (value instanceof Object) {
		return Object.values(value);
	} else {
		return [];
	}
};

export const flatTree = (tree: any[], key = 'children') => {
	const flat: any[] = [];
	tree.forEach((item) => {
		flat.push(item);
		if (item[key]) {
			flat.push(...flatTree(item[key]));
		}
	});
	return flat;
};

export const mapCommaSeperatedIds = (ids: string = '') => (typeof ids === 'string' ? ids.split(',').map((i) => parseInt(i.trim())) : []);

export const isEmpty = (i: any) => i === null || i === undefined || i === '';
