export const setToken = (response: any) => {
	// console.log(response);
	// Store auth token in localStorage
	localStorage.setItem('t', response.token);
	// Store user in localStorage
	!!response.user && localStorage.setItem('u', JSON.stringify(response.user));
	// Store workspaceId in localStorage
	// console.log(response.workspace);
	!!response.workspace && localStorage.setItem('w', JSON.stringify(response.workspace));
};
