import { Form, Input } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { TextAreaProps } from 'antd/es/input';

const { Item } = Form;
const { TextArea } = Input;

interface FormTextAreaRule {
	name: NamePath;
	label?: string;
	placeholder?: string;
	className?: string;
	required?: boolean;
	readOnly?: boolean;
	rows?: number;
	outerClass?: string;
	textAreaProps?: TextAreaProps;
}

const FormTextArea = (props: FormTextAreaRule) => {
	const {
		name,
		label = '',
		placeholder,
		className,
		required = false,
		readOnly = false,
		rows = 3,
		outerClass = 'col-span-12',
		textAreaProps = {}
	} = props;

	return (
		<div className={outerClass}>
			<Item label={label} className={className || 'm-0 mb-2'} name={name} rules={[{ required }]} data-testid={name}>
				<TextArea rows={rows} readOnly={readOnly} className='w-100' style={{ resize: 'none' }} placeholder={placeholder} {...textAreaProps} />
			</Item>
		</div>
	);
};
export default FormTextArea;
