import { lazy, Suspense } from 'react';

const Loadable = (importFunc: any, fallback?: any) => {
	const LazyComponent = lazy(importFunc);
	// import loading here
	return (props: any) => (
		<Suspense fallback={!!fallback ? fallback : null}>
			<LazyComponent {...props} />
		</Suspense>
	);
};

export default Loadable;
