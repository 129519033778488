import type { Dayjs } from 'dayjs';
import { Form, DatePicker } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { getPopupContainer } from 'dashboard/utils/helper';
import { PickerProps } from 'antd/es/date-picker/generatePicker';
import { US_DATE_FORMAT } from 'dashboard/constants';

const { Item } = Form;

interface FormDatePickerRule {
	name: NamePath;
	label?: string;
	className?: string;
	outerClass?: string;
	required?: boolean;
	readOnly?: boolean;
	placeholder?: string;
	datepickerProps?: PickerProps<Dayjs>;
}

const FormDatePicker = (props: FormDatePickerRule) => {
	const {
		label,
		name,
		className = 'm-0 mb-3',
		required = false,
		outerClass = 'col-span-6',
		datepickerProps = {},
		placeholder = 'Select Date'
		// readOnly = false
	} = props;

	return (
		<div className={outerClass}>
			<Item name={name} label={label} rules={[{ required }]} className={className} data-testid={name}>
				<DatePicker
					allowClear
					className='w-full'
					format={US_DATE_FORMAT}
					placeholder={placeholder}
					getPopupContainer={getPopupContainer}
					{...datepickerProps}
				/>
			</Item>
		</div>
	);
};
export default FormDatePicker;
