import { CancelTokenSource } from 'axios';

export const APP_TITLE = 'Webflare';
export const APP_DESC = 'Build Funnels, Sites and much more to deploy on high performance edge cloud infrastructure across the globe.';

export const IS_PRODUCTION = !window.location.href.includes('localhost');

export const ENV = IS_PRODUCTION ? 'production' : 'development';
export const API_URL = IS_PRODUCTION ? 'https://a.webflare.com/' : 'http://localhost:8787/';
export const SITE_URL = 'https://app.webflare.com/';
export const WEB_URL = 'https://app.webflare.com/';
export const CDN_URL = 'https://weblit.cloud/';
export const US_DATE_FORMAT = 'MM/DD/YYYY';
export const PUBLIC_DOMAIN = 'webflare.page';

export type RequestFnWithToken = (cancelToken?: CancelTokenSource) => Promise<void>;

export const required = { required: true };
// eslint-disable-next-line no-template-curly-in-string
export const validateMessages = { required: '${label} is required' };

export const STRIPE_CLIENT_ID = 'ca_LiU2OUx1pdB5qWnbO1WtO3aVOmC81U73';
export const STRIPE_PUBLIC_KEY = 'pk_test_EiiyMjkCmTW9tXwwyS5Dc4oe';
export const GOOGLE_MAP_API_KEY = 'AIzaSyADnlUTJi6qrSejtFP01LHGCWGW3JfjlHI';

export const LAMBDA_FUNCTIONS = {
	FILE_MANAGER: 'https://62arujubbj.execute-api.us-west-2.amazonaws.com/',
	IMAGE_PROCESSING: 'https://z9489aycq4.execute-api.us-west-2.amazonaws.com/'
} as const;
