import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { SiteWizardQuestions } from 'dashboard/components/sites/AddNewSite/BuildQuestions';
import Wizard from 'dashboard/ShareComponents/Wizard';
import HTTP from 'dashboard/utils/https';

const RegistrationWizardQuestions: any[] = [
	{
		title: 'Which of the following describes you best?',
		description: `We'll help you get started based on your responses.`,
		type: 'radio',
		name: 'role',
		options: [
			{ label: 'Realtor', value: 'realtor' },
			{ label: 'Financial Advisor', value: 'financial-advisor' },
			{ label: 'Insurance Agent', value: 'insurance-agent' },
			{ label: 'Freelancer', value: 'freelancer' },
			{ label: 'Other', value: 'other' }
		]
	}
	// ...SiteWizardQuestions
];

const RegistrationQuestions = () => {
	const navigate = useNavigate();
	const [error, setError] = useState<string>('');

	useEffect(() => {
		HTTP('GET', 'account/meta').then((data) => {
			// If already answered questions, redirect to dashboard
			if (!!data.registrationAnswers) {
				navigate('/dashboard', { replace: true });
			}
		});
	}, [navigate]);

	const onFinish = async (v: any) => {
		try {
			// const siteFields = SiteWizardQuestions.map((i) => i.name);
			// await HTTP('PUT', 'account/meta', { registrationAnswers: JSON.stringify(omit(v, siteFields)) });
			navigate('/dashboard', { replace: true });
		} catch (e: any) {
			setError(e?.message || e);
		}
	};

	return (
		<div className='mt-10'>
			<Wizard steps={RegistrationWizardQuestions} onFinish={onFinish} error={error} />{' '}
		</div>
	);
};

export default RegistrationQuestions;
