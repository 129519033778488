import React from 'react';

export default class ErrorBoundary extends React.Component {
	override state = { hasError: false, error: null };

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error };
	}

	override componentDidCatch(error: any, errorInfo: any) {
		// You can also log the error to an error reporting service
	}

	override render() {
		// if (this.state.hasError) {
		// 	// You can render any custom fallback UI
		// 	return <h1>Something went wrong.</h1>;
		// }
		// @ts-ignore
		return this.props.children;
	}
}
