import { Form } from 'antd';

const FormItemHidden = ({ name }: { name: string }) => {
	return (
		<Form.Item name={name} data-testid={name} hidden noStyle>
			<></>
		</Form.Item>
	);
};

export default FormItemHidden;
